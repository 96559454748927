<template>
  <v-container>
    <!-- <p class="display-1 text--primary">
      <v-icon class="mr-2">mdi-cogs</v-icon>Processos
    </p> -->
    <v-row>
      <v-col cols="12">
        <ConsultaProcessos />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ConsultaProcessos from "@/components/sistema/processos/ConsultaProcessos";
export default {
  name: "Processos",

  components: {
    ConsultaProcessos
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Processos");
  }
};
</script>
