var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.infoProcedure,"loading":_vm.loading,"paginate":true,"search":_vm.search,"sort-by":"ordem"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Processos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(175),expression:"175"}],attrs:{"color":"primary","dark":""},on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Novo Processo ")],1),_c('ModalCadastroProcessos',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"editProcedure":_vm.procedure},on:{"close":function($event){_vm.dialog = false}}})],1)]},proxy:true},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.descricao))+" ")]}},{key:"item.ordem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusOrdem(item.ordem))+" ")]}},{key:"item.data_proc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_proc))+" ")]}},{key:"item.usuario",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusUsuario(item.usuario))+" ")]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusProcesso(item.ativo) == 'Ativo' ? 'green' : 'red',"dark":""}},[_vm._v(" "+_vm._s(_vm.statusProcesso(item.ativo))+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(177),expression:"177"}],attrs:{"name":'edit',"icon":""},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(178),expression:"178"}],attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }