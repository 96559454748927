<template>
  <BaseTable
    class="mt-5"
    :headers="headers"
    :items="infoProcedure"
    :loading="loading"
    :paginate="true"
    :search="search"
    sort-by="ordem"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Processos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer> </v-spacer>

        <v-btn
          v-can-access="175"
          color="primary"
          dark
          @click="(dialog = true), (labelBtn = 'Salvar')"
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          Novo Processo
        </v-btn>
        <ModalCadastroProcessos
          :dialog="dialog"
          :labelBtn="labelBtn"
          :editProcedure="procedure"
          @close="dialog = false"
        />
      </v-toolbar>
    </template>

    <template v-slot:[`item.descricao`]="{ item }">
      {{ item.descricao | TitleCase }}
    </template>
    <template v-slot:[`item.ordem`]="{ item }">
      {{ statusOrdem(item.ordem) }}
    </template>
    <template v-slot:[`item.data_proc`]="{ item }">
      {{ item.data_proc | BrazilianStandardDate }}
    </template>
    <template v-slot:[`item.usuario`]="{ item }">
      {{ statusUsuario(item.usuario) }}
    </template>
    <template v-slot:[`item.ativo`]="{ item }">
      <v-chip
        :color="statusProcesso(item.ativo) == 'Ativo' ? 'green' : 'red'"
        dark
      >
        {{ statusProcesso(item.ativo) }}
      </v-chip>
    </template>
    <template v-slot:[`item.acoes`]="{ item }">
      <!-- <v-btn icon v-can-access="177" @click="editItem(item)" color="orange" dark>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon v-can-access="178" @click="deleteItem(item)" class="ml-2" color="red" dark small>
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>-->
      <IconBottom
        :name="'edit'"
        icon
        v-can-access="177"
        @click="editItem(item)"
      />
      <IconBottom
        :name="'delete'"
        v-can-access="178"
        @click="deleteItem(item)"
      />
    </template>
  </BaseTable>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import dateMixin from "@/mixins/dateMixin";
import BaseTable from "@/components/shared/NewBaseTable.vue";
import ModalCadastroProcessos from "@/components/sistema/processos/ModalCadastroProcessos";
import serviceProcedure from "@/services/procedures";
export default {
  components: {
    BaseTable,
    ModalCadastroProcessos,
    IconBottom
  },

  mixins: [dateMixin],

  data() {
    return {
      labelBtn: "Salvar",
      dialog: false,
      procedure: {},
      loading: false,
      search: "",
      headers: [
        { text: "Processos", value: "descricao" },
        { text: "Dias", value: "dias" },
        { text: "Ordem", value: "ordem" },
        { text: "Data do Processo", value: "data_proc" },
        { text: "Usuários", value: "usuario" },
        { text: "Status", value: "ativo" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      infoProcedure: []
    };
  },

  methods: {
    async fetchProcedures() {
      try {
        const response = await serviceProcedure.getProcessos({ per_page: -1 });
        this.infoProcedure = response.data.data;
        this.actualPage = response.data.page;
        this.lastPage = response.data.last_page;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao buscar os Processos"
        });
      }
    },

    statusProcesso(item) {
      if (item == "S") item = "Ativo";
      if (item == "N") item = "Inativo";

      return item;
    },

    statusOrdem(item) {
      if (item == null || item == "null      ") return "Não informado";
      return item;
    },

    statusUsuario(item) {
      if (item == null) return "Não informado";
      return item;
    },

    editItem(item) {
      this.procedure = Object.assign({}, item);
      this.procedure.data_proc = this.procedure.data_proc.split(" ")[0];
      this.labelBtn = "Editar";
      this.dialog = true;
    },

    async deleteItem(item) {
      await this.$swal.confirm(
        "Deletar processo",
        `Deseja deletar o processo ${item.descricao}?`
      );
      try {
        await serviceProcedure.deleteProcessos(item.id_processo);
        this.$notify({
          type: "success",
          text: "Processo deletado"
        });
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Processo"
        });
      }
    }
  },

  mounted() {
    this.fetchProcedures();
  }
};
</script>

<style></style>
