<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar" ? "Editar Processo" : "Cadastrar Processo"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Nome Processo"
                  v-model="formProcedure.procedure"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Descrição"
                  v-model="formProcedure.descricao"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Departamanto"
                  v-model="formProcedure.id_depto"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Dias"
                  v-model="formProcedure.dias"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field
                  label="Ordem"
                  v-model="formProcedure.ordem"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <BaseDatePicker
                  label="Data do Processo"
                  v-model="formProcedure.data_proc"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <BaseSelect
                  label="Ativo"
                  @itemSelect="formProcedure.ativo = $event"
                  :items="[
                    { text: 'Sim', value: 'S' },
                    { text: 'Não', value: 'N' }
                  ]"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="
            labelBtn != 'Salvar' ? editarProcedure() : cadastrarProcedure()
          "
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import serviceProcedure from "@/services/procedures";
import { mapActions } from "vuex";
export default {
  name: "ModalCadastroProcessos",

  mixins: [rules],

  components: {
    BaseSelect: () => import("@/components/shared/BaseSelect"),
    BaseDatePicker: () => import("@/components/shared/BaseDatePicker")
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },

    labelBtn: {
      type: String
    },

    editProcedure: {
      type: Object
    }
  },

  data() {
    return {
      formProcedure: {
        procedure: "",
        descricao: "",
        id_depto: "",
        dias: "",
        ordem: "",
        data_proc: "",
        ativo: "",
        // id_tipo_processo: "",
        usuario: "teste"
      },
      defaultState: {
        procedure: "",
        descricao: "",
        id_depto: "",
        dias: "",
        ordem: "",
        data_proc: "",
        ativo: "",
        // id_tipo_processo: "",
        usuario: "teste"
      }
    };
  },

  watch: {
    dialog(value) {
      return value;
    },

    labelBtn(value) {
      return value;
    },

    editProcedure() {
      if (this.editProcedure) {
        this.formProcedure = this.editProcedure;
      }
    }
  },

  methods: {
    ...mapActions({
      fetchProcedures: "procedure/fetchProcedures"
    }),

    async cadastrarProcedure() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          await serviceProcedure.postProcessos(this.formProcedure),
            this.$notify({
              type: "success",
              text: "Processo adicionado"
            });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao adicionar Processo"
          });
        } finally {
          this.close();
        }
      } else {
        this.$notify({
          type: "error",
          text: "Formulário inválido"
        });
      }
    },

    async editarProcedure() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        try {
          await serviceProcedure.putProcessos(
            this.formProcedure.id_processo,
            this.formProcedure
          ),
            this.$notify({
              type: "success",
              text: "Processo editado"
            });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Erro ao editar Processo"
          });
        } finally {
          this.close();
        }
      } else {
        this.$notify({
          type: "error",
          text: "Formulário inválido"
        });
      }
    },

    close() {
      this.formProcedure = this.defaultState;
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
